// extracted by mini-css-extract-plugin
export var blogPostDescriptionField = "BlogPostCategoryNewForm__blogPostDescriptionField__ArxKF";
export var buttonCreateContainer = "BlogPostCategoryNewForm__buttonCreateContainer__Sgf1W";
export var column = "BlogPostCategoryNewForm__column__kPvSW";
export var editor = "BlogPostCategoryNewForm__editor__kPks1";
export var fieldInstructions = "BlogPostCategoryNewForm__fieldInstructions__vXaZj";
export var flexContainer = "BlogPostCategoryNewForm__flexContainer__lMWqk";
export var grid = "BlogPostCategoryNewForm__grid__Ikj8d";
export var iconDelete = "BlogPostCategoryNewForm__iconDelete__gsmiJ";
export var iconView = "BlogPostCategoryNewForm__iconView__vjQA9";
export var labelContainer = "BlogPostCategoryNewForm__labelContainer__xIaok";
export var row = "BlogPostCategoryNewForm__row__gMHUf";